import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Product } from "../Product";
import SliderBtn from "@assets/icons/sliderBtn";

const Slider: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const length = 10;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <div className={styles.slider}>
      <button className={styles.leftArrow} onClick={prevSlide}>
        <div className={styles.iconWrapper}>
          <SliderBtn />
        </div>
      </button>
      <button className={styles.rightArrow} onClick={nextSlide}>
        <div className={styles.iconWrapper}>
          <SliderBtn />
        </div>
      </button>
      {Array(length)
        .fill(true)
        .map((_, index) => {
          return (
            <div
              className={`${styles.slide} ${index === current ? styles.slideActive : ""}`}
            >
              {index === current && <Product />}
            </div>
          );
        })}
    </div>
  );
};

export default Slider;
