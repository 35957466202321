import { useState } from "react";
import QuestionCircle from "@assets/icons/question-circle.tsx";
import Notifications from "@assets/icons/notifications.tsx";
import Sun from "@assets/icons/sun.tsx";
import classNames from "classnames";
import { NotificationPopup } from "./NotificationPopup";
import { notifications } from "./NotificationPopup/mock";
import { getIconColor } from "./iconUtils";
import { useOutsideClick } from "src/utils/useOutsideClick";

export const HeaderActions = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const popupRef = useOutsideClick(() => {
    setNotificationOpen(false);
    setActiveButton(null);
  });

  const toggleButton = (buttonName: string) => {
    setActiveButton((prevActiveButton) =>
      prevActiveButton === buttonName ? null : buttonName,
    );
  };

  return (
    <div className="header-actions">
      <button
        className={classNames("header-actions__button", {
          activeBtn: activeButton === "questionCircle",
        })}
        onClick={() => toggleButton("questionCircle")}
      >
        <QuestionCircle color={getIconColor("questionCircle", activeButton)} />
      </button>
      <button
        ref={notificationOpen ? popupRef : null}
        className={classNames("header-actions__button", {
          activeBtn: activeButton === "notifications",
        })}
        onClick={() => {
          toggleButton("notifications");
          setNotificationOpen(!notificationOpen);
        }}
        aria-expanded={notificationOpen}
      >
        <div className="header-actions__notifications-count">2</div>
        <Notifications color={getIconColor("notifications", activeButton)} />
        {notificationOpen && (
          <NotificationPopup notifications={notifications} />
        )}
      </button>

      <button
        className={classNames("header-actions__button", {
          activeBtn: activeButton === "sun",
        })}
        onClick={() => toggleButton("sun")}
      >
        <Sun color={getIconColor("sun", activeButton)} />
      </button>
    </div>
  );
};
