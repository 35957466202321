import { OptimisationItem } from "./components/OptimisationItem";
import { items } from "./components/mock";
import styles from "./styles.module.scss";

const Optimisation = () => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <OptimisationItem
          key={item.title}
          title={item.title}
          initialValue={item.initialValue}
          route={item.route}
        />
      ))}
    </div>
  );
};

export default Optimisation;
