export default () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7452 11.3333H5.88889C3.74112 11.3333 2 9.59222 2 7.44444C2 5.29667 3.74112 3.55556 5.88889 3.55556H20.7452M11.2548 28.4444H26.1111C28.2589 28.4444 30 26.7033 30 24.5556C30 22.4078 28.2589 20.6667 26.1111 20.6667H11.2548M2 24.5556C2 27.5624 4.43756 30 7.44444 30C10.4513 30 12.8889 27.5624 12.8889 24.5556C12.8889 21.5487 10.4513 19.1111 7.44444 19.1111C4.43756 19.1111 2 21.5487 2 24.5556ZM30 7.44444C30 10.4513 27.5624 12.8889 24.5556 12.8889C21.5487 12.8889 19.1111 10.4513 19.1111 7.44444C19.1111 4.43756 21.5487 2 24.5556 2C27.5624 2 30 4.43756 30 7.44444Z"
        stroke="#5C5C5A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
