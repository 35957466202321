import ToolsIcon from "@assets/icons/toolsIcon";
import ToolsPopup from "./ToolsPopup";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useOutsideClick } from "src/utils/useOutsideClick";

interface ToolsProps {
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  scrollToSection: (area: string, sectionId: number) => void;
}

function Tools({ isActive, setIsActive, scrollToSection }: ToolsProps) {
  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  const popupRef = useOutsideClick(handleClose);

  return (
    <div className={styles.container}>
      <div
        className={isActive ? styles.visibleBox : styles.hiddenBox}
        ref={popupRef}
      >
        <div
          className={`${styles.tools} ${isActive ? styles.active : ""}`}
          onClick={handleClick}
        >
          <ToolsIcon />
        </div>
        {isActive && (
          <ToolsPopup onClose={handleClose} scrollToSection={scrollToSection} />
        )}
      </div>
      <button className={classNames(styles.button, isActive && styles.blurred)}>
        save changes
      </button>
    </div>
  );
}

export default Tools;
