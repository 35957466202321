import "./index.scss";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { CustomTooltip } from "./CustomTooltip";
import {
  generateData,
  Metric,
  MetricData,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";

interface ChartProps {
  selectedMetrics: Metric[];
}

const COLORS = [
  "#80C67A",
  "#1BB08D",
  "#FFFFFF",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export const Chart: React.FC<ChartProps> = ({ selectedMetrics }) => {
  const dateRange = useDashboardStore((state) => state.dateRange);

  const [chartData, setChartData] = useState<MetricData[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const start = dateRange.startDate.getTime();
        const end = dateRange.endDate.getTime();
        const data = generateData(start, end);
        setChartData(data);
      } catch (error) {
        console.error("Error while fetch chart data", error);
      }
    };

    loadData();
  }, [dateRange, selectedMetrics]);

  const convertDate = useCallback((timestamp: number) => {
    return format(new Date(timestamp), "d MMM");
  }, []);

  const convertCurrency = useCallback((value: number) => {
    return `$${value}`;
  }, []);

  const gradientMetricIndex = useMemo(() => {
    if (selectedMetrics.length === 0 || chartData.length === 0) return null;

    const firstDataPoint = chartData[0];
    let maxMetricIndex = 0;

    selectedMetrics.forEach((metric, index) => {
      if (
        firstDataPoint[metric.key] >
        firstDataPoint[selectedMetrics[maxMetricIndex].key]
      ) {
        maxMetricIndex = index;
      }
    });

    return maxMetricIndex;
  }, [selectedMetrics, chartData]);

  return (
    <div className="sales-performance__chart" style={{ zIndex: "0" }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 0,
          }}
        >
          <defs>
            {gradientMetricIndex !== null && (
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={COLORS[gradientMetricIndex % COLORS.length]}
                  stopOpacity={0.3}
                />
                <stop
                  offset="100%"
                  stopColor={COLORS[gradientMetricIndex % COLORS.length]}
                  stopOpacity={0}
                />
              </linearGradient>
            )}
          </defs>
          <CartesianGrid stroke="#1F1F20" />
          <XAxis
            dataKey="date"
            tickFormatter={convertDate}
            interval="preserveStartEnd"
            stroke="#7C7C78"
          />
          <YAxis
            type="number"
            stroke="#7C7C78"
            tickFormatter={convertCurrency}
            domain={["auto", "auto"]}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              fill: "transparent",
              stroke: "white",
              strokeDasharray: 4,
            }}
          />
          {selectedMetrics.map((metric, index) => {
            if (index === gradientMetricIndex) {
              return (
                <Area
                  key={metric.key}
                  type="monotone"
                  dataKey={metric.key}
                  stroke={COLORS[index % COLORS.length]}
                  fill="url(#colorGradient)"
                  strokeWidth={2}
                  dot={false}
                />
              );
            }
            return (
              <Line
                key={metric.key}
                type="monotone"
                dataKey={metric.key}
                stroke={COLORS[index % COLORS.length]}
                strokeWidth={2}
                dot={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
