import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ConfigImage from "@assets/icons/config.svg";
import CalendarImage from "@assets/icons/calendar.svg";
import { ConfigModal } from "@components/config-modal";
import { CalendarModal } from "@components/calendar-modal";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { fetchWidgets } from "src/utils/fetchWidgets";
import { formatDateRange } from "src/utils/formatDate";

interface TabProps {
  onApply: (widgets: IWidget[]) => void;
}

interface Tab {
  label: string;
  code: string;
  icon: string;
}

const tabList: Tab[] = [
  {
    label: "Configure",
    code: "config",
    icon: ConfigImage,
  },
  {
    label: "01.01.24 — 07.01.24",
    code: "calendar",
    icon: CalendarImage,
  },
];

export const Tab = ({ onApply }: TabProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("");
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });

  const dateRange = useDashboardStore((state) => state.dateRange);
  const setDateRange = useDashboardStore((state) => state.setDateRange);

  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );

  const setWidgets = useDashboardStore((state) => state.setWidgets);
  const widgets = useDashboardStore((state) => state.widgets);

  const modalRef = useOutsideClick(() => setActiveTab(""));

  useEffect(() => {
    const formattedDateRange = formatDateRange(
      dateRange.startDate,
      dateRange.endDate,
    );
    setSelectedDateRange(formattedDateRange);
  }, [dateRange]);

  const toggleTab = (code: string, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const newActiveTab = activeTab === code ? "" : code;
    setActiveTab(newActiveTab);

    if (newActiveTab) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      setModalPosition({
        x: buttonRect.right,
        y: buttonRect.bottom,
        width: buttonRect.width,
      });
    }
  };

  const closeModal = () => {
    setActiveTab("");
  };

  const handleDateChange = async (startDate: Date, endDate: Date) => {
    setDateRange({ startDate, endDate });

    try {
      const newData = await fetchWidgets(
        startDate.toISOString().split("T")[0],
        endDate.toISOString().split("T")[0],
      );

      const updatedWidgets = widgets.map((widget, index) => ({
        ...widget,
        data: newData[index],
      }));

      setWidgets(updatedWidgets);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  return (
    <div className={styles.tab}>
      {tabList.map((item) => (
        <div
          key={item.label}
          onClick={(e) => toggleTab(item.code, e)}
          className={clsx(styles.element, {
            [styles["element--active"]]: activeTab === item.code,
          })}
        >
          <img src={item.icon} alt={t(item.label)} className={styles.icon} />
          <p className={styles.tabItem}>
            {item.code === "calendar" ? selectedDateRange : t(item.label)}
          </p>
          {activeTab === item.code && (
            <div
              ref={modalRef}
              className={styles.modalContainer}
              onClick={(e) => e.stopPropagation()}
            >
              {item.code === "config" && (
                <ConfigModal
                  buttonWidth={modalPosition.width}
                  closeModal={closeModal}
                  initialWidgets={widgets}
                  setWidgets={setWidgets}
                  onApply={onApply}
                />
              )}
              {item.code === "calendar" && (
                <CalendarModal
                  buttonWidth={modalPosition.width}
                  closeModal={closeModal}
                  onDateChange={handleDateChange}
                  initialRange={dateRange}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
