import React from "react";
import { NavigateFunction, NavLink, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { productItems } from "./mock";
import { ROUTES } from "src/router/routes";
import classNames from "classnames";

interface ProductPopupProps {
  closePopup: () => void;
}

const getRouteLink = (label: string) => {
  switch (label) {
    case "My Products":
      return ROUTES.MYPRODUCT;
    case "CRO Module":
      return ROUTES.CRO;
    default:
      return ROUTES.ROOT;
  }
};

export const ProductPopup: React.FC<ProductPopupProps> = ({ closePopup }) => {
  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          <div className={styles.section}>
            {productItems.map((item) => {
              const link = getRouteLink(item.label);

              return (
                <NavLink
                  key={item.label}
                  to={link}
                  className={({ isActive }) =>
                    classNames(styles.item, {
                      [styles.active]: isActive,
                    })
                  }
                  onClick={closePopup}
                >
                  {item.label} {item.icon}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
