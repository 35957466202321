import { useState } from "react";
import styles from "./styles.module.scss";
import { aspectData } from "./mock";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import MetricsArrow from "@assets/icons/metricsArrow";

interface AspectOptimisationProps {
  handleRemove: () => void;
}

function AspectOptimisation({ handleRemove }: AspectOptimisationProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupVisibility, setGroupVisibility] = useState<
    Record<string, boolean>
  >({});

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleGroupVisibility = (aspect: string) => {
    setGroupVisibility((prevState) => ({
      ...prevState,
      [aspect]: !prevState[aspect],
    }));
  };

  const aspects = [
    "Effectiveness",
    "Ingredients",
    "Price",
    "Quality control",
    "Side effects",
    "Taste",
    "Varied",
  ];

  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        contentHidden={toggleExpand}
        isContentClosed={isExpanded}
      />
      <div className={styles.container}>
        <h3 className={styles.title}>Aspect optimization</h3>
        <div className={styles.dropdown}>
          <select className={styles.select}>
            <option>Good to Neutral</option>
            <option>Neutral to Negative</option>
          </select>
        </div>
        {isExpanded && (
          <div className={styles.aspectSection}>
            <div className={styles.aspectColumns}>
              {aspects.map((aspect) => (
                <div key={aspect} className={styles.aspectColumn}>
                  <button
                    className={styles.aspectLabel}
                    onClick={() => toggleGroupVisibility(aspect)}
                  >
                    {aspect}
                    <div
                      className={groupVisibility[aspect] ? styles.rotated : ""}
                    >
                      <MetricsArrow />
                    </div>
                  </button>
                  {groupVisibility[aspect] && (
                    <div className={styles.dropdownContent}>
                      <p>Item 1</p>
                      <p>Item 2</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.graphItems}>
          <p className={styles.positiveTitle}>positive</p>
          <p className={styles.neutralTitle}>neutral</p>
          <p className={styles.negativeTitle}>negative</p>
        </div>
        {aspectData.map((aspect) => (
          <div key={aspect.name} className={styles.aspect}>
            <span className={styles.aspectName}>{aspect.name}</span>
            <div className={styles.bars}>
              <div
                className={styles.positive}
                style={{ width: `${aspect.positive}%` }}
              >
                {aspect.positive}%
              </div>
              <div
                className={styles.neutral}
                style={{ width: `${aspect.neutral}%` }}
              >
                {aspect.neutral}%
              </div>
              <div
                className={styles.negative}
                style={{ width: `${aspect.negative}%` }}
              >
                {aspect.negative}%
              </div>
            </div>
          </div>
        ))}
        {isExpanded && (
          <div className={styles.aspectChange}>
            <h3>Aspect change</h3>
            <div className={styles.changeGrid}>
              {aspectData.map((aspect) => (
                <div key={aspect.name} className={styles.changeItem}>
                  <p className={styles.positive}>
                    0.00%
                    <br />
                    <span className={styles.unit}>P</span>
                  </p>
                  <p className={styles.neutral}>
                    0.00%
                    <br />
                    <span className={styles.unit}>Neu</span>
                  </p>
                  <p className={styles.negative}>
                    0.00%
                    <br />
                    <span className={styles.unit}>N</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </SectionContainer>
  );
}

export default AspectOptimisation;
