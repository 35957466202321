import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { initializeCalendarData } from "src/utils/initialCalendarData";

export interface IDashboardState {
  widgets: IWidget[];
  availableWidgets: IWidget[];
  modalWidgets: IWidget[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  setWidgets: (widgets: IWidget[]) => void;
  setAvailableWidgets: (widgets: IWidget[]) => void;
  setModalWidgets: (widgets: IWidget[]) => void;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  updateWidgets: (widgets: IWidget[], availableWidgets: IWidget[]) => void;
  reset: () => void;
}

const { today, monday } = initializeCalendarData();

export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: [],
  modalWidgets: [],
  dateRange: {
    endDate: today,
    startDate: monday,
  },
  setWidgets: (widgets: IWidget[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: IWidget[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  setDateRange: (range: { startDate: Date; endDate: Date }) =>
    set({ dateRange: range }),
  updateWidgets: (widgets: IWidget[], availableWidgets: IWidget[]) => {
    set({ widgets, availableWidgets });
  },
  reset: () =>
    set({
      widgets: [],
      availableWidgets: [],
      modalWidgets: [],
      dateRange: { startDate: new Date(), endDate: new Date() },
    }),
}));
