import styles from "./styles.module.scss";
import PhotoCard from "./components/PhotoCard";
import { photoCardMocks } from "./mock";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";

function PhotoOptimisation() {
  return (
    <OptimisationPageWrapper>
      <div className={styles.gridContainer}>
        {photoCardMocks.map((mock) => (
          <PhotoCard
            key={mock.id}
            imageUrl={mock.imageUrl}
            optimizationScore={mock.optimizationScore}
            ctrChange={mock.ctrChange}
            salesChange={mock.salesChange}
            ctrValue={mock.ctrValue}
            salesValue={mock.salesValue}
            id={mock.id}
            imageDescription={mock.imageDescription}
            imageCritique={mock.imageCritique}
            hypothesis={mock.hypothesis}
            howToTest={mock.howToTest}
            percentageImpact={mock.percentageImpact}
          />
        ))}
      </div>
    </OptimisationPageWrapper>
  );
}

export default PhotoOptimisation;
