import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";
import AmazonIcon from "@assets/icons/AmazonIcon.png";
import AmazonBlackIcon from "@assets/icons/AmazonBlackIcon.png";
import Combine from "@assets/img/combine.png";
import { useOutsideClick } from "src/utils/useOutsideClick";
import { DropdownItems } from "./renderDropdown";

const CustomSelect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  useEffect(() => {
    setIsAnyCheckboxChecked(Object.values(checkedItems).some((item) => item));
  }, [checkedItems]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCheckboxes = (e) => {
    e.stopPropagation();
    setShowCheckboxes(!showCheckboxes);
  };

  const handleCheckboxClick = (e, index) => {
    e.stopPropagation();
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleItemClick = (index) => (e) => {
    e.stopPropagation();
    if (showCheckboxes) {
      handleCheckboxClick(e, index);
    } else {
      setCheckedItems({ [index]: true });
      setShowCheckboxes(true);
    }
  };

  const handleApplyClick = (e) => {
    e.stopPropagation();
    setCheckedItems({});
    setShowCheckboxes(false);
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={`${styles.dropdownContainer} ${isOpen ? styles.dropdownActive : ""}`}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleDropdown();
          }
        }}
        tabIndex={0}
        role="button"
      >
        <button
          className={`${styles.customSelect} ${isOpen ? styles.active : ""}`}
          onClick={toggleDropdown}
        >
          <img src={isOpen ? AmazonBlackIcon : AmazonIcon} alt="Amazon" />
          <span>Amazon</span>
          <ChevronDown />
        </button>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <DropdownItems
              showCheckboxes={showCheckboxes}
              checkedItems={checkedItems}
              handleCheckboxClick={handleCheckboxClick}
              handleItemClick={handleItemClick}
            />
            <button
              className={`${styles.combineButton} ${showCheckboxes ? styles.applybtn : ""} ${
                isAnyCheckboxChecked ? styles.checkedButton : ""
              }`}
              onClick={showCheckboxes ? handleApplyClick : toggleCheckboxes}
            >
              {showCheckboxes ? (
                <>Apply</>
              ) : (
                <>
                  Combine <img src={Combine} alt="Combine" />
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
