import React from "react";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import styles from "./styles.module.scss";
import { blockTitles } from "./mock";

const Options: React.FC = () => {
  return (
    <div className={styles.options}>
      {blockTitles.map((title) => (
        <div key={title} className={styles.block}>
          {title}
        </div>
      ))}
      <button className={styles.applyButton}>
        APPLY CHANGES <AuthButtonArrow />
      </button>
    </div>
  );
};

export default Options;
