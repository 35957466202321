import { useRef, useState } from "react";
import Tools from "./components/Tools";
import ProductDate from "./components/ProductDate";
import Sections from "./components/Sections";
import styles from "./styles.module.scss";
import classNames from "classnames";

function CROPage() {
  const [isActive, setIsActive] = useState(false);
  const sectionRefs = useRef({});

  const scrollToSection = (area, sectionId) => {
    const key = `${area}-${sectionId}`;
    const sectionElement = sectionRefs.current[key];
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Tools
        scrollToSection={scrollToSection}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      <div
        className={classNames(styles.content, { [styles.blurred]: isActive })}
      >
        <ProductDate />
        <Sections sectionRefs={sectionRefs} />
      </div>
    </>
  );
}

export default CROPage;
