import { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./calendar.scss";
import { DateRangePicker, RangeKeyDict, Range } from "react-date-range";
import { useOutsideClick } from "src/utils/useOutsideClick";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import closeModalIcon from "@assets/icons/closeModal.svg";
import { presets, Preset } from "./calendarPreset";

interface CalendarModalProps {
  buttonWidth: number;
  closeModal: () => void;
  onDateChange: (startDate: Date, endDate: Date) => void;
  initialRange: { startDate: Date; endDate: Date };
}

export const CalendarModal = ({
  closeModal,
  onDateChange,
  initialRange,
}: CalendarModalProps) => {
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: initialRange.startDate,
    endDate: initialRange.endDate,
    key: "selection",
  });

  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);

  const modalRef = useOutsideClick(() => {
    closeModal();
  });

  useEffect(() => {
    document.querySelectorAll(".rdrWeekDays").forEach((weekDays) => {
      weekDays.querySelectorAll(".rdrWeekDay").forEach((day, index) => {
        const days = ["S", "M", "T", "W", "T", "F", "S"];
        day.textContent = days[index];
      });
    });
  }, []);

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange(ranges.selection);
    setSelectedPreset(null);
  };

  const applyPreset = (preset: Preset) => {
    setSelectionRange(preset.range);
    setSelectedPreset(preset.label);
  };

  const applyDateSelection = () => {
    onDateChange(selectionRange.startDate!, selectionRange.endDate!);

    closeModal();
  };
  const getPreviousMonth = (date: Date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 2);
    return newDate;
  };

  return (
    <div ref={modalRef} className="modalContainer">
      <button className="closeButton" onClick={closeModal}>
        <img src={closeModalIcon} alt="Close" />
        Cancel
      </button>
      <div className="calendarContainer">
        <div>
          <DateRangePicker
            shownDate={getPreviousMonth(selectionRange.startDate)}
            ranges={[selectionRange]}
            onChange={handleSelect}
            months={3}
            direction="horizontal"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className="calendar"
          />
          <button className="applyButton" onClick={applyDateSelection}>
            APPLY
          </button>
        </div>

        <div className="presetsContainer">
          <div className="presetsBox">
            <div className="title">Preset</div>
            {presets.map((preset, index) => (
              <button
                className={`presetsBtn ${selectedPreset === preset.label ? "selected" : ""}`}
                key={index}
                onClick={() => applyPreset(preset)}
              >
                {preset.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
