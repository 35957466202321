import React from "react";
import styles from "./styles.module.scss";
import { mockData } from "./mock";
import CloseKeyword from "@assets/icons/closeKeyword";
import AddKeywords from "@assets/icons/addKeywords";

interface GenerateProps {
  onGenerateClick: () => void;
}

const Generate: React.FC<GenerateProps> = ({ onGenerateClick }) => {
  return (
    <div className={styles.generate}>
      <div className={styles.title}>
        <input type="text" placeholder="Title" value={mockData.title} />
      </div>
      <div className={styles.bulletPoints}>
        <textarea placeholder="Bullet Points" value={mockData.bulletPoints} />
      </div>

      <div className={styles.keywords}>
        <div className={styles.header}>
          <p>Keywords</p>
          <button className={styles.downloadBtn}>DOWNLOAD CSV</button>
        </div>
        <div>
          {mockData.keywords.map((keyword) => (
            <span key={keyword}>
              {keyword} <CloseKeyword />
            </span>
          ))}
          <button className={styles.addIcon} onClick={onGenerateClick}>
            <AddKeywords />
          </button>
        </div>
      </div>
      <div className={styles.images}>
        <p>Images</p>
        <div className={styles.box}>
          {mockData.images.map((image) => (
            <img key={image.alt} src={image.src} alt={image.alt} />
          ))}
          <div className={styles.addImgIcon}>
            <AddKeywords />
          </div>
        </div>
      </div>
      <div className={styles.searchTerms}>
        <p>Search terms</p>
        <input type="text" value={mockData.searchTerms} />
      </div>
      <button className={styles.generateButton} onClick={onGenerateClick}>
        GENERATE
      </button>
    </div>
  );
};

export default Generate;
