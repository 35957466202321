import { useState } from "react";
import styles from "./styles.module.scss";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";
import { optimizationData } from "./mock";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";
import MySvgComponent from "./BackGroundFrame";

interface OptimisationDetailsProps {
  handleRemove: () => void;
}

function OptimisationDetails({ handleRemove }: OptimisationDetailsProps) {
  const [isProblemClosed, setIsProblemClosed] = useState(false);

  const handleToggle = () => {
    setIsProblemClosed(!isProblemClosed);
  };

  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        contentHidden={handleToggle}
        isContentClosed={!isProblemClosed}
      />
      <div
        className={
          isProblemClosed ? styles.containerClosed : styles.containerOpen
        }
      >
        {optimizationData.map((item, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.header}>
              <span className={styles.label}>{item.label}</span>
              <WidgetIcon />
            </div>
            <div className={styles.content}>
              <div
                className={styles.backgroundFrame}
                style={{
                  height: `${item.percentage}%`,
                }}
              >
                <MySvgComponent baseColor={item.color} uniqueId={index} />
              </div>
              {item.percentage > 0 ? (
                <span className={styles.percentage}>{item.percentage}%</span>
              ) : (
                <span className={styles.text}>{item.text}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
}

export default OptimisationDetails;
