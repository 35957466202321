export const formatDateRange = (startDate: Date, endDate: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedStartDate = startDate
    .toLocaleDateString("default", options)
    .replace(/\//g, ".");
  const formattedEndDate = endDate
    .toLocaleDateString("default", options)
    .replace(/\//g, ".");

  return `${formattedStartDate} — ${formattedEndDate}`;
};
